import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  private _apiUrl = environment.apiUrl;

  constructor(private http: HttpClient,) { }

  obtenerParametros(): Observable<any> {
    return this.http.get<any>(`${this._apiUrl}/parametro/`)
    .pipe(
      tap((resp: any) => {
        return resp }),
      catchError((err: any) => {
        throw err;
      })
    );

  }

  obtenerParametroPorNombre(nombreAtributo: string): Observable<any> {
    return this.http.get<any>(`${this._apiUrl}/parametro/${nombreAtributo}`)
    .pipe(
      tap((resp: any) => {
        return resp }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

}
