import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultaPadronService {

  private readonly STORAGE_KEY = 'dataClient';

  constructor() {
    const storedData = localStorage.getItem(this.STORAGE_KEY);
    this.dataClient = new BehaviorSubject<any>(storedData ? JSON.parse(storedData) : {});
   }

  private dataClient: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  dataClient$: Observable<any> = this.dataClient.asObservable();

  getDataClient(): any { return this.dataClient.getValue(); }
  setDataClient(data: any): void { 
    this.dataClient.next(data); 
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
  }

  clearDataClient(): void {
    this.dataClient.next({});
    localStorage.removeItem(this.STORAGE_KEY);
  }
  
  private dataParametros: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  dataParametros$: Observable<any> = this.dataParametros.asObservable();

  getDataParametros(): any { return this.dataParametros.getValue(); }
  setDataParametros(data: any): void { this.dataParametros.next(data); }

}
