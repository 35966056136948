import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Utils {

  constructor( ) { }

  removeBase64Prefix(base64String: string): string {
    const res = base64String.split(',')[1]
    return res
  }

  imprimirConsulta() {
    window.print();
  }

  isObjectEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
  }


}
