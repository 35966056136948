import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PadronService {

  private _apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  consultarPadron(dni: string, sexo: string): Observable<any> {
    return this.http.get<any>(`${this._apiUrl}/elector?dni=${dni}&sexo=${sexo}`)
    .pipe(
      tap((resp: any) => {
        return resp }),
      catchError((err: any) => {
        throw err;
      })
    );
  }

  crearReclamo(body: any) {
    const url = `${this._apiUrl}/reclamo`;
    return this.http.post<any>(url, body)
      .pipe(
        tap((resp: any) => { return resp }),
        catchError((err: any) => {
          throw err;
        })
      );
  }

}
