import { ChangeDetectorRef, inject, signal } from '@angular/core';
import { Parametros } from './models/parametros.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PadronService } from './services/padron.service';
import { ParametrosService } from './services/parametros.service';
import { Subject, takeUntil } from 'rxjs';
import { Utils } from './shared/utils/utils';
import { ConsultaPadronService } from './consulta-padron/consulta-padron.service';
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export const siteKey = 'AIzaSyD2g-pUeOFqnviDqMswXUjWNcMqGzrdpKY';

export class AppDependecies {
  [x: string]: any;

  public googleMapsApiKey: string = 'AIzaSyBhsKQQhToVeFK68Ps1G8pSx16nfxTJqzc';
  public reCaptchaKey: string = '6LcGqJ0qAAAAALeI9WhwkUKFo1WYFrM8zWuG3awH';

  protected dataParametros: any = {
    "fecha_desde_habilitada": "",
    "fecha_hasta_habilitada": "",
    "texto_deshabilitada": "",
    "fecha_desde_reclamo": "",
    "fecha_hasta_reclamo": "",
    "tipo_padron": "",
    "texto_provisorio": "",
    "texto_definitivo": "",
    "informacion_renglon_1": "",
    "informacion_renglon_2": "",
    "informacion_renglon_3": "",
    "informacion_renglon_4": "",
    "informacion_renglon_5": "",
    "informacion_renglon_6": "",
    "organismo_direccion": "",
    "organismo_correo": "",
    "texto_reclamo": "",
    "correo_respuesta_reclamo": "",
    "asunto_respuesta_reclamo": "",
    "cuerpo_respuesta_reclamo": "",
    "texto_autoridad_mesa_1": "",
    "texto_autoridad_mesa_2": ""
  }

  protected listOptionsDefaultClaims: any[] = [
    { id: 1, option: 'No estoy en el padrón' },
    { id: 2, option: 'Error en los datos' },
    { id: 3, option: 'Otros' },
  ];

  protected  listOptionsSexoDefault: any[] = [
    { id: 1, sexo: 'Femenino', value: 'F' },
    { id: 2, sexo: 'Masculino', value: 'M' },
    { id: 3, sexo: 'No Binario', value: 'X' },
  ];

  //  =============== States ==================
  protected imageFrente: any;
  protected imageDorso: any;
  protected selectValue: boolean = false;
  protected noSoyRobot: any = ''
  protected descriptionText: string = '';
  protected warningFrente: boolean = false
  protected warningDorso: boolean = false
  protected isImg: boolean = false
  protected listOptions: any[] = [];

  //  =============== Signals ==================
  protected parametros = signal<Parametros>(this.dataParametros) ;
  protected estaHabilitado = signal<boolean>(false);
  protected esDefinitivo = signal<boolean>(false);
  protected reclamoHabilitado = signal<boolean>(false);
  protected listImages = signal<string[]>([]);


  //  =============== Dependency injection ===============
  protected _generateClaim!: FormGroup;
  protected _consultaForm!: FormGroup;
  protected _resultadoForm: FormGroup | null = null;
  protected readonly _fb = inject(FormBuilder);
  protected readonly _router = inject(Router);
  protected readonly _cd = inject(ChangeDetectorRef)
  protected readonly _utils = inject(Utils)
  protected readonly _takeUntil = takeUntil;

  //  =============== Services ===============
  protected readonly _padronService = inject(PadronService);
  protected readonly _parametrosService = inject(ParametrosService)
  protected readonly _consultaPadronService = inject(ConsultaPadronService);

  protected readonly unsubscribeAll: Subject<any> = new Subject<any>();

  protected setHabilitado(fechaDesde: string, fechaHasta: string){
    // [] Means inclusive comparison
    this.estaHabilitado.set(dayjs().isBetween(fechaDesde, fechaHasta, "day", "[]"))
  }

  protected setReclamoHabilitado(fechaDesde: string, fechaHasta: string){
    // [] Means inclusive comparison
    this.reclamoHabilitado.set(dayjs().isBetween(fechaDesde, fechaHasta, "day", "[]"))
  }

  protected camposRequeridos(): void {
    this._generateClaim.markAllAsTouched();
  }


  protected clearField(item: string): void {
    while (this._generateClaim.get(item)?.value) {
      this._generateClaim.get(item)?.setValue('');
    }
  }


}
